<template>
  <div class="orders">
    <el-tabs v-model="orderStates" @tab-click="navChange">
      <el-tab-pane label="全部" name="all" />
      <el-tab-pane label="待付款" name="0" />
      <el-tab-pane label="已付款" name="1" />
      <el-tab-pane label="签约" name="4" />
    </el-tabs>
    <!-- 签约 -->
    <div v-if="orderStates == 4">
      <el-table :data="studentInfo">
        <el-table-column label="合同ID" prop="contractId" align="center" />
        <el-table-column label="签约人姓名" prop="name" align="center" />
        <el-table-column label="签约手机号" prop="contact" align="center" />
        <el-table-column label="创建时间" prop="createTime" align="center" />
        <el-table-column label="合同状态" align="center">
          <template slot-scope="{ row }">
            {{ contractStatus[row.status] }}
          </template>
        </el-table-column>
        <el-table-column label="操作" prop="createTime" align="center">
          <template slot-scope="{ row }">
            <el-button v-if="row.status == 'SIGNING'" type="text" @click="submit(row)">{{ row.status == "SIGNING" ? "签约"
              : "查看" }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div v-if="!orderStates || (orderStates && orderStates.length == 0)" class="nothing">
        <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
        <div class="nothing-text">暂无订单哦~</div>
      </div>
    </div>
    <!-- 跳转内容 orderStates  1 全部订单-->
    <div v-else class="allOrder">
      <!-- 单个模块 -->
      <div v-for="(item, i) in list" :key="i" class="allOrders">
        <!-- 内容左 -->
        <div class="dis_flex_between" style="align-items: center">
          <div class="dd_id">订单号:{{ item.orderNumber }}</div>
          <div v-if="new Date(item.countDownTime) > new Date()" class="dd_statuss">
            <div class="wait">等待付款</div>
            <div>
              {{
                `00:${min <= 9 ? `0${min}` : min}:${sed <= 9 ? `0${sed}` : sed}` }} </div>
            </div>
          </div>
          <div v-for="(chil, chilIndex) in item.orderParticularsList" :key="chilIndex" v-if="!chil.active"
            class="allOrdersCent">
            <!-- <div class="dis_flex_between" style="aic">
            <div class="dd_id">订单号:{{ chil.orderId }}</div>
            <div class="dd_statuss">
              <div class="wait">等待付款</div>
              <div>{{ chil.countDownTime }}</div>
            </div>
          </div> -->
            <div class="dd_line" />
            <div class="dis_flex_start" style="padding: 20px; padding-bottom: 0px">
              <div class="allOrdersCentL">
                <div v-show="item.orderState === 1 || item.orderState === 4" class="img_shadow">
                  {{
                    chil.commodityType != 1
                      ? type[chil.commodityType]
                      : chil.productLine == 1
                        ? "普通班"
                        : chil.productLine == 2
                          ? "精品班"
                          : "VIP专属班"
                  }}
                </div>
                <!-- <div v-show="item.orderState === 0" class="img_shadow wait">
                待付款
              </div>
              <div v-show="item.orderState === 2" class="img_shadow wait">
                已退款
              </div> -->
                <img v-if="chil.commodityType != 14" class="img" :src="chil.commodityImg" alt="" />
                <img v-else class="imgjc" :src="chil.commodityImg" alt="" />
              </div>
              <!-- 内容中 -->
              <div class="allOrdersCentZ">
                <div class="dis_flex_start" style="align-items: center">
                  <!-- <div class="dd_ty">{{ type[chil.commodityType] }}</div> -->
                  <div class="dd_til elp">{{ chil.commodityName }}</div>
                </div>

                <div class="flex-c m-t-16" style="margin-top: 25px">
                  <div v-if="chil.productLine == '5'" class="dd_ty">1V1直播</div>
                  <template v-else>
                    <div class="dis_flex_start">
                      <!-- <div class="ty-tips">x{{ chil.commodityNumber || 1 }}</div> -->
                      <div class="p2">优惠券-¥{{ item.discountPrice }}</div>
                      <!-- <div v-if="item.orderParticularsList.length == 1 &&
                        item.commodityType == 14
                        " class="ty-tips">
                        运费：{{ item.freight ? item.freight : 0 }}元
                      </div> -->
                    </div>
                  </template>
                </div>
                <div class="dd_status">
                  <div v-if="item.orderState === 3" class="refunding">退费中</div>
                  <div v-if="item.orderState === 2" class="successful">
                    退费成功
                  </div>
                </div>
              </div>
              <!-- 内容右 -->
              <div v-if="chilIndex == 0" class="allOrdersCentR">
                <div v-if="item.orderState === 0" class="p1">
                  <span>¥ </span>{{ item.paidPrice }}
                </div>
                <!-- item.orderPrice -->
                <div v-if="item.orderState === 1 || item.orderState === 4" class="p1">
                  <span>实付款 <span style="color: #f34417">¥</span> </span>{{ item.paidPrice }}
                </div>
                <div class="p2">
                  <!-- 优惠券-¥{{ item.discountPrice }} -->
                </div>

                <div v-if="item.commodityType != 14 && chilIndex == 0" class="p4">
                  <div v-show="item.orderState === 1 &&
                    (domain.isShow == 1 ? item.targetTime < 7 : false)
                    " class="refundBtn" @click="refundI(item)">
                    申请退费
                  </div>
                  <!-- <div
                class="refundBtn"
                @click="refundI(item)"
                v-show="
                  item.orderState === 1 &&
                  domain.isShow == 1 &&
                  item.targetTime < 7
                "
              >
                申请退费
              </div>
              <div
                class="refundBtn"
                @click="openPopup(item)"
                v-show="
                  item.orderState === 1 &&
                  (domain.isShow == 0 || item.targetTime > 7)
                "
              >
                联系班主任
              </div> -->
                  <div style="margin-right: 5px" class="refundBtn" v-if="
                    item.orderState == 1 &&
                    item.status == 1 &&
                    item.paidPrice > 0
                  " @click="toinvoice(item)">
                    申请开票
                  </div>
                  <div v-if="item.status == 0 || item.status == 2" style="margin-right: 5px" class="refundBtn"
                    @click="seeinvoice(item)">
                    查看发票
                  </div>
                  <div v-show="item.orderState === 1 || item.orderState === 4" class="goLearnBtn refundBtn"
                    @click="toDetails(chil)">
                    去学习
                  </div>

                  <div v-show="item.isEliminate === 1" class="refundBtn">
                    已销课
                  </div>

                  <div v-show="item.orderState === 0 &&
                    item.orderParticularsList.length <= 1
                    " class="godd refundBtn" @click="dingdn(item)">
                    立即付款
                  </div>
                </div>
                <div v-else-if="item.orderParticularsList.length == 1" class="p4">
                  <div v-show="item.orderState === 1 &&
                    (domain.isShow == 1 ? item.targetTime < 7 : false)
                    " class="refundBtn" @click="refundI(item)">
                    申请退费
                  </div>
                  <!-- <div
                class="refundBtn"
                @click="refundI(item)"
                v-show="
                  item.orderState === 1 &&
                  domain.isShow == 1 &&
                  item.targetTime < 7
                "
              >
                申请退费
              </div>
              <div
                class="refundBtn"
                @click="openPopup(item)"
                v-show="
                  item.orderState === 1 &&
                  (domain.isShow == 0 || item.targetTime > 7)
                "
              >
                联系班主任
              </div> -->
                  <!-- <div v-show="item.consigneeStatus === 1" class="goLearnBtn refundBtn" @click="toDetails(chil)">
                    催发货
                  </div> -->
                  <div v-show="item.consigneeStatus === 2" class="refundBtn" @click="getShipTraceInfo(item.id)">
                    物流信息
                  </div>
                  <div v-show="item.consigneeStatus === 2" class="godd refundBtn" @click="confirm(item)">
                    确认收货
                  </div>
                  <div v-show="item.consigneeStatus === 3" class="refundBtn" @click="confirm(item)">
                    去评价
                  </div>
                  <div v-show="item.consigneeStatus === 3" class="refundBtn" @click="getShipTraceInfo(item.id)">
                    查看物流
                  </div>
                  <div v-show="item.consigneeStatus === 0" class="refundBtn" @click="deleteOrder(item.id)">
                    取消订单
                  </div>
                  <div v-show="item.consigneeStatus === 0" class="godd refundBtn" @click="dingdn(item)">
                    付款
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="item.orderParticularsList.length > 1" class="p5 dis_flex_between">
            <div class="freight">
              <!-- 运费：{{ item.freight ? item.freight : 0 }}元 -->
              <span @click="shows(item)" style="">{{ !item.showind ? "收起" : "展开" }}
                <img v-if="!item.showind" src="@/assets/img/textmall/show.png" alt="" />
                <img v-else src="@/assets/img/textmall/down.png" alt="" />
              </span>
            </div>

            <!-- <div
                class="refundBtn"
                @click="refundI(item)"
                v-show="
                  item.orderState === 1 &&
                  domain.isShow == 1 &&
                  item.targetTime < 7
                "
              >
                申请退费
              </div>
              <div
                class="refundBtn"
                @click="openPopup(item)"
                v-show="
                  item.orderState === 1 &&
                  (domain.isShow == 0 || item.targetTime > 7)
                "
              >
                联系班主任
              </div> -->
            <div style="display: flex">
              <!-- <div v-show="item.consigneeStatus === 1 && item.orderParticularsList.length > 1
                " class="goLearnBtn refundBtn" @click="toDetails(chil)">
                催发货
              </div> -->
              <div v-show="item.consigneeStatus === 2" class="refundBtn" @click="getShipTraceInfo(item.id)">
                物流信息
              </div>
              <div v-show="item.consigneeStatus === 2" class="godd refundBtn" @click="confirm(item)">
                确认收货
              </div>
              <div v-show="item.consigneeStatus === 3" class="refundBtn" @click="confirm(item)">
                去评价
              </div>
              <div v-show="item.consigneeStatus === 3" class="refundBtn" @click="getShipTraceInfo(item.id)">
                查看物流
              </div>
              <div v-show="item.consigneeStatus === 0" class="refundBtn" @click="deleteOrder(item.id)">
                取消订单
              </div>
              <div v-show="item.consigneeStatus === 0" class="godd refundBtn" @click="dingdn(item)">
                付款
              </div>
            </div>
          </div>
        </div>
        <div v-if="!list || (list && list.length == 0)" class="nothing">
          <div class="nothing-text">暂无订单哦~</div>
        </div>
        <!-- 分页 -->
        <el-pagination :total="total" class="pagination partner-pagination" :current-page="pageNum"
          :page-sizes="[8, 16, 24, 40]" :page-size="pageSize" background layout="total, sizes, prev, pager, next"
          @current-change="handleCurrentChange" @size-change="handleSizeChange" />
      </div>
      <el-dialog top="5vh" :visible.sync="dialogVisible" width="60%" :before-close="handleClose">
        <div class="iframeBox">
          <iframe class="iframe" :src="contractUrl" />
        </div>
      </el-dialog>
      <el-dialog class="addsdialogVisible" title="物流信息" center :visible.sync="addsdialogVisible">
        <div class="logistics">
          <div class="logistics_title">
            <div class="code">
              <img :src="Couriers[logisticsDetails.shipperCode]" alt="" />
              {{ logisticsDetails.shipperCode }}：{{
                logisticsDetails.logisticCode
              }}
            </div>
            <div link class="copy" @click="onCopy(logisticsDetails.logisticCode)">
              复制
            </div>
          </div>
          <div class="logistics_item" v-for="(item, index) in logisticsList" :key="index">
            <div class="acceptTime">{{ item.acceptTime }}</div>
            <div class="dian" :class="index == 0 ? 'active' : ''"></div>
            <div class="acceptStation">{{ item.acceptStation }}</div>
          </div>
        </div>
        <!-- <div class="iframeBox">
        <iframe class="iframe" :src="contractUrl" />
      </div> -->
      </el-dialog>

      <!-- 联系班主任 -->
      <el-dialog :visible.sync="popupVisible" width="500px" :before-close="popupClose">
        <div class="popup_wrap">
          <div class="CR_code_box">
            <img class="QR_code_img" :src="QRCodeUrl" alt="" />
            <div class="block upper_left" />
            <div class="block top_right" />
            <div class="block lower_left" />
            <div class="block lower_right" />
          </div>
          <div class="contact_details">
            <img class="icon" src="@/assets/img/Home/phone.png" alt="" />
            <span>联系方式：{{ phoneNumber }}</span>
          </div>
          <div class="text_box">
            <span>可</span>
            <span class="red_text">扫码上方二维码</span>
            <span>或</span>
            <span class="red_text">拨打手机号</span>
            <span>联系班主任</span>
          </div>
        </div>
      </el-dialog>
      <el-dialog class="ssss" :visible.sync="invoiceDio" width="630px" title="申请发票">
        <invoice @isOK="isOK" v-if="invoiceDio" :invoiceItem="invoiceItem"></invoice>
      </el-dialog>
      <el-dialog class="ssss" :visible.sync="seeinvoiceDio" width="630px" title="查看发票">
        <invoiceDetail @isOK="isOK" v-if="seeinvoiceDio" :invoiceItem="invoiceItem">
        </invoiceDetail>
      </el-dialog>
    </div>
</template>
<script>
import { getClassOrderList } from "@/api/user";
import {
  setReady,
  getToken,
  getInfo,
  getDomainLogo,
  getDomain,
} from "@/api/cookies";
import { showTypeEnum, Courier } from "@/api/emun";
import {
  deleteTextBookOrder,
  getShipTraceInfo,
  updateConsigneeStatus,
} from "@/api/mykecheng";
import { OrderClient } from "@/api/orderClient";
const orderClient = new OrderClient();
import invoice from "@/views/mine/my/invoice.vue";
import invoiceDetail from "@/views/mine/my/invoiceDetail.vue";

import Vue from "vue";
export default {
  components: { invoice, invoiceDetail },

  data() {
    return {
      seeinvoiceDio: false,
      invoiceList: "",

      invoiceItem: "",
      invoiceDio: false,
      userId: null, // 用户信息
      orderStates: "all",
      list: [],
      logisticsList: [],
      logisticsDetails: {},
      pageSize: 8,
      Couriers: Courier,
      // 订单 第几页
      pageNum: 1,
      total: 0,
      type: showTypeEnum,
      userInfo: null,
      studentInfo: [],
      contractStatus: {
        DRAFT: "草稿",
        RECALLED: "已撤回",
        SIGNING: "签署中",
        REJECTED: "已退回",
        COMPLETE: "已完成",
        EXPIRED: "已过期",
        FILLING: "拟定中",
        FAILED: "签署失败",
        INVALIDING: "作废中",
        INVALIDED: "已作废",
      },
      dialogVisible: false,
      contractUrl: null,
      domain: null,
      orderDownTime: null,
      addsdialogVisible: null,
      popupVisible: false,
      QRCodeUrl: "",
      min: "",
      sed: "",
      phoneNumber: "",
    };
  },
  async created() {
    this.userInfo = getInfo();
    this.domain = getDomain();
    this.getOrderIsShow();
    this.userId = localStorage.getItem("userId");
    // 调用获取学生订单信息接口
    await this.getList(); // (全部)
  },
  methods: {
    async seeinvoice(item) {
      this.invoiceItem = item;
      this.seeinvoiceDio = true;
    },
    isOK() {
      this.getList();

      this.invoiceDio = false;
    },
    toinvoice(item) {
      this.invoiceItem = item;
      this.invoiceDio = true;
    },
    toDetails(item) {
      console.log(item);
      if (item.productLine || item.commodityType) {
        // this.$router.push({ path: '/seventhPage/mycurriculum', query: { id: item.commodityId }})
        window.open("/partnerPage/mycurriculum", "_blank");
        return;
      }
      if (item.productLine === "5") {
        this.$router.push({
          path: "/VIP/oneToOneClass",
          query: {
            id: item.commodityId,
          },
        });
        return;
      }
      console.log(55555555555);
      Vue.prototype.goTypeDetail(
        item.commodityType,
        item.commodityId,
        undefined,
        undefined,
        undefined,
        item.productLine
      );
    },
    // 标签页修改
    navChange() {
      this.pageSize = 8;
      this.pageNum = 1;
      this.list = [];
      if (this.orderStates == "4") {
        this.getStudentInfo();
      } else {
        this.getList();
      }
    },
    // 调用获取学生订单信息接口(全部)
    /* orderState 0 未购买 1 已购买 2 已退费 3 待退费 */
    getList() {
      getClassOrderList(
        this.pageSize,
        this.pageNum,
        this.userId,
        this.orderStates == "all" ? "" : this.orderStates
      ).then((res) => {
        this.list = res.rows;
        this.list.map((item) => {
          item.showind = false;
          if (
            item.orderParticularsList.length > 1 &&
            item.commodityType == 14
          ) {
            this.shows(item);
          }
          const payTime = new Date(item.payTime);
          if (
            new Date(item.countDownTime) > new Date() &&
            item.orderState == 0
          ) {
            this.orderDownTime =
              new Date(item.countDownTime).getTime() - new Date().getTime();
            this.min = new Date(this.orderDownTime).getMinutes();
            this.sed = new Date(this.orderDownTime).getMinutes();
            let timer = null;
            timer = setInterval(() => {
              this.sed--;
              if (this.sed == 0) {
                this.min--;
                this.sed = 60;
                if (this.min < 0) {
                  item.orderdue = true;
                  clearInterval(timer);
                  return;
                }
              }
            }, 1000);
          }
          const currentTime = new Date();
          const targetTime = (currentTime - payTime) / 1000 / 60 / 60 / 24;
          this.$set(item, "targetTime", targetTime);
        });
        this.total = res.total;
      });
    },

    /*
      查询学生签约信息
      contractId	是	合同ID
      contact	是	签约者联系方式
    */
    getStudentInfo() {
      const data = {
        contact: this.userInfo.mobile,
        /* contact:'13287740516' */
      };
      orderClient.selectQysStudent(data).then((res) => {
        this.studentInfo = res.data;
      });
    },
    /*
      点击签约
      contractId	是	合同ID
      contact	是	签约者联系方式
    */
    submit(row) {
      const data = {
        contractId: row.contractId,
        contact: row.contact,
      };
      orderClient.contractPageurl(data).then((res) => {
        if (res.code == 0 && res.data) {
          this.contractUrl = res.data;
          this.dialogVisible = true;
        } else {
          this.$message({
            type: "success",
            message: res.msg,
          });
        }
      });
    },
    /* 关闭签约弹窗 */
    handleClose() {
      this.dialogVisible = false;
      this.getStudentInfo();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    async deleteOrder(val) {
      const res = await deleteTextBookOrder(val);
      if (res.code == 0) {
        this.getList();
        this.$message.success(res.msg);
      }
    },
    async shows(val) {
      val.showind = !val.showind;
      if (val.showind) {
        this.list.forEach((e) => {
          if (e == val) {
            val.orderParticularsList.forEach((p, ind) =>
              ind == 0
                ? this.$set(p, "active", val.showind)
                : this.$set(p, "active", !val.showind)
            );
          }
        });
      } else {
        this.list.forEach((e) => {
          if (e == val) {
            val.orderParticularsList.forEach((p, ind) =>
              this.$set(p, "active", val.showind)
            );
          }
        });
      }
    },
    async confirm(val) {
      const res = await updateConsigneeStatus(val.id);
      if (res.code == 0) {
        this.getList();
        this.$forceUpdate();
      }
    },
    async getShipTraceInfo(val) {
      const res = await getShipTraceInfo(val);
      if (res.code == 0) {
        this.logisticsList = res.data.traces;
        this.logisticsDetails = res.data;
        this.addsdialogVisible = true;
      }
    },
    // 全部订单 去支付
    dingdn(item) {
      if (item.commodityType == 14) {
        this.$router.push({
          path: "/mall/orderView",
          query: { id: item.orderNumber },
        });
      } else {
        this.$router.push({
          name: "个人中心订单页",
          params: { orderNumber: item.orderNumber, img: item.commodityImg },
        });
      }
    },
    onCopy(val) {
      console.log(1111);
      this.$copyText(val).then(
        (e) => {
          console.log("复制成功：", e);
        },
        (e) => {
          console.log("复制失败：", e);
        }
      );
    },
    // 去学习
    goLearn(row) {
      Vue.prototype.goTypeDetail(
        row.commodityType,
        row.commodityId,
        row.isSkip,
        row.commodityName
      );
    },
    /* 退费 */
    refundI(item) {
      this.$confirm("选择退费后，不可取消退费，确认要继续吗？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const data = {
            orderId: item.id,
          };
          orderClient.mAddOrder(data).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "申请成功，请耐心等待审核!",
              });
              this.getList();
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    // 联系班主任
    openPopup() {
      this.popupVisible = true;
    },
    popupClose() {
      this.popupVisible = false;
    },
    getOrderIsShow() {
      const data = {
        userid: this.userInfo.id,
      };
      orderClient.getOrderIsShow(data).then((res) => {
        this.QRCodeUrl = res.data.wechatCode;
        this.phoneNumber = res.data.phoneNum;
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .addsdialogVisible {
  .el-dialog {
    width: 879px;
    height: 645px;
    overflow: auto;
    background: #ffffff;
    border-radius: 12px 12px 12px 12px;
    opacity: 1;

    .logistics {
      .logistics_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 811px;
        height: 59px;
        background: #f6f6f6;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        padding: 10px 20px;
        margin-bottom: 20px;

        .code {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 18px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;

          img {
            margin-right: 10px;
          }
        }

        .copy {
          width: 36px;
          height: 25px;
          cursor: pointer;
          font-size: 18px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #00ab8c;
        }
      }

      .logistics_item {
        display: flex;
        align-items: flex-start;
        justify-content: start;

        .acceptTime {
          width: 173px;
          margin-top: 6px;
        }

        .dian {
          width: 14px;
          height: 14px;
          background: #ffffff;
          border-radius: 50%;
          opacity: 1;
          border: 1px solid #00ab8c;
          margin: 9px 10px 0;
        }

        .active {
          background: #00ab8c;
        }

        .acceptStation {
          width: 604px;
          line-height: 32px;
        }
      }
    }
  }
}

// 缩宽
.orders {
  width: 100%;
  height: 100%;
  // display: table;
  margin: auto;
  padding: 0px 0;

  // 标题
  .or-title {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #27323f;
  }

  // 跳转盒子
  // 跳转盒子
  .basicsName {
    width: 100%;
    height: 38px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    padding: 0px 32px;
    border-bottom: 1px solid #eee;

    .basicsNames {
      /*   width: 65px; */
      height: 100%;
      margin-right: 41px;
      cursor: pointer;

      .basicsNamesTop {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }

      .basicsNamesTops {
        color: #333333;
      }

      .basicsNamesCen {
        width: 24px;
        height: 2px;
        margin: 14px auto 0px;
        background: #ef0d0d;
        border-radius: 1px;
      }
    }
  }

  // 跳转内容 orderStates = 1 全部订单
  .allOrder {
    width: 100%;
    height: 100%;
    margin-top: 24px;
    padding-bottom: 10px;

    .p5 {
      // margin-top: 10px;
      padding: 0 20px;
      display: flex;

      .freight {
        // width: 57px;
        height: 14px;
        font-size: 14px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 400;
        color: #333333;

        span {
          cursor: pointer;
        }

        img {
          display: inline-block;
          width: 12px;
          margin-left: 5px;
          padding-bottom: 2px;
        }
      }

      .refundBtn {
        width: 110px;
        height: 36px;
        border-radius: 18px 18px 18px 18px;
        opacity: 1;
        border: 1px solid #b9bfc4;
        cursor: pointer;
        margin-left: 12px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #777a82;
        line-height: 36px;
        text-align: center;
      }

      .goLearnBtn {
        border: 1px solid #4a6af0;
        color: #4a6af0;
      }

      .godd {
        background: #4a6af0;
        color: #fff;
      }
    }

    .dd_id {
      font-size: 10px;
      height: 40px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333;
      line-height: 40px;
      margin-right: 14px;
      margin-left: 20px;
    }

    .dd_statuss {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 156px;
      height: 26px;
      background: rgba(0, 171, 139, 0.1);
      border-radius: 17px 17px 17px 17px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #4a6af0;

      .wait {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 76px;
        height: 26px;
        background: #4a6af0;
        border-radius: 17px 0px 17px 17px;
        opacity: 1;
        font-size: 14px;
        margin-right: 9px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }

    // 模块
    .allOrders {
      // width: 100%;
      width: 993px;
      min-height: 194px;
      background: #ffffff;
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.06);
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      margin-bottom: 10px;
      padding-bottom: 15px;

      .dd_line {
        margin: 0 auto;
        width: 953px;
        height: 0px;
        opacity: 1;
        border: 1px solid #eeeeee;
      }

      .allOrdersCent {
        width: 100%;
        background-color: #fff;
        // display: flex;
        height: auto;

        // 左
        .allOrdersCentL {
          position: relative;
          width: 177.78px;
          background: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 12px;
          flex-shrink: 0;

          .img_shadow {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 22px;
            padding: 0px 10px;
            border-radius: 8px 0px 8px 0px;
            opacity: 1;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 22px;
            background: rgba(0, 0, 0, 0.3);
          }

          .wait {
            background: #ff5e51;
            color: #ffffff;
          }

          .img {
            width: 177.78px;
            height: 100px;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
          }

          .imgjc {
            width: 69px;
            height: 92px;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
          }
        }

        // 中
        .allOrdersCentZ {
          // width: calc(100% - 192px - 258px);
          flex: 1;
          height: 100%;

          .p2 {
            // width: 82px;
            text-align: center;
            height: 23px;
            background: #fffaeb;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            padding: 0 7px;
            font-size: 14px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #f69801;
          }

          .dd_til {
            width: 400px;
            font-size: 16px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;
          }

          .dd_ty {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 16px;
            background: rgba(74, 106, 240, 0.2);
            border-radius: 3px 3px 3px 3px;
            opacity: 1;
            line-height: 16px;
            margin-right: 5px;
            font-size: 12px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: rgba(74, 106, 240, 1);
            padding: 0px 4px;
          }

          .ty-tips {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 14px;
            margin-left: 8px;
          }

          .dd_status {
            display: flex;
            align-items: center;
            margin-top: 20px;

            .refunding {
              width: 54px;
              height: 18px;
              border: solid 1px #ff5e51;
              border-radius: 2px;
              color: #ff5e51;
              font-size: 14px;
              text-align: center;
              line-height: 16px;
            }

            .successful {
              width: 68px;
              height: 18px;
              border: 1px solid #3b9d4b;
              border-radius: 2px;
              color: #3b9d4b;
              font-size: 14px;
              text-align: center;
              line-height: 16px;
            }
          }
        }

        // 右
        .allOrdersCentR {
          width: 258px;
          flex-shrink: 0;
          height: 100%;
          background: #ffffff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;

          .p1 {
            font-size: 16px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #f34417;
            line-height: 20px;

            span {
              font-size: 14px;
              font-family: Source Han Sans CN-Normal, Source Han Sans CN;
              font-weight: 400;
              color: #777a82;
              margin-right: 2px;
            }
          }

          .p2 {
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ff7b1c;
            line-height: 14px;
            margin-top: 12px;
          }

          .p4 {
            margin-top: 20px;
            display: flex;

            .refundBtn {
              width: 110px;
              height: 36px;
              border-radius: 18px 18px 18px 18px;
              opacity: 1;
              border: 1px solid #b9bfc4;
              cursor: pointer;
              margin-left: 26px;
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #777a82;
              line-height: 36px;
              text-align: center;
            }

            .goLearnBtn {
              border: 1px solid #006de7;
              color: #006de7;
            }

            .godd {
              background: #4a6af0;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.mantil {
  height: 104px;
  line-height: 104px;
  font-size: 24px;
  font-family: Microsoft YaHei-Bold;
  font-weight: bold;
  color: #333333;
  text-align: left;
}

.flex_co {
  display: flex;
  flex-direction: column;
}

.iframeBox {
  width: 100%;
  height: 800px;

  .iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}

/deep/ .el-tabs {
  .el-tabs__header {
    margin: 0px !important;
  }

  .el-tabs__item {
    padding-top: 24px;
    padding-bottom: 15px;
    height: auto !important;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333;
    line-height: 16px;
  }

  .is-active {
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
  }

  .el-tabs__active-bar {
    // width: 20px !important;
    height: 3px;
    background: #4a6af0;
    border-radius: 1px 1px 1px 1px;
    opacity: 1;
    // margin-left: 22px;
  }

  .el-tabs__nav-wrap::after {
    height: 1px;
    background: #ececec;
  }
}

.nothing {
  height: auto;
}

.popup_wrap {
  height: 476px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .CR_code_box {
    width: 260px;
    height: 260px;
    background-color: #fff4f3;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 42px;
    position: relative;

    .QR_code_img {
      width: 160px;
      height: 160px;
      border-radius: 8px;
    }

    .block {
      width: 60px;
      height: 60px;
      position: absolute;
    }

    .upper_left {
      border-top: 6px solid #ff5e51;
      border-left: 6px solid #ff5e51;
      top: 0;
      left: 0;
    }

    .top_right {
      border-top: 6px solid #ff5e51;
      border-right: 6px solid #ff5e51;
      top: 0;
      right: 0;
    }

    .lower_left {
      border-bottom: 6px solid #ff5e51;
      border-left: 6px solid #ff5e51;
      bottom: 0;
      left: 0;
    }

    .lower_right {
      border-bottom: 6px solid #ff5e51;
      border-right: 6px solid #ff5e51;
      bottom: 0;
      right: 0;
    }
  }

  .contact_details {
    display: flex;
    align-items: center;
    color: #333333;
    font-size: 20px;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 11px;
    }
  }

  .text_box {
    font-size: 20px;
    margin-top: 30px;

    .red_text {
      color: #ff5e51;
    }
  }
}
</style>
<style lang="less">
.ssss {
  .el-dialog {
    border-radius: 10px !important;
  }

  .el-dialog__header {
    font-weight: 800;
    border-bottom: 1px solid #DDDDDD;
  }

  .el-dialog__body {
    padding: 0 !important;
  }
}
</style>
